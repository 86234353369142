import Vue from 'vue'
import Vuex from 'vuex'
import tab from './tab.js'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        tab
    },
    state: {
        // 存储token
        token: "",
        userName: "" // 可选
    },
    getters: {
        getToken(state) {
            return state.token || localStorage.getItem("eztokenedu") || "";
        }
    },
    mutations: {
        // 修改token，并将token存入localStorage
        setToken(state, token) {
            state.token = token;
            localStorage.setItem('eztokenedu', token);
            console.log('store、localstorage保存token成功！');
        },
        delToken(state) {
            state.token = "";
            localStorage.removeItem("eztokenedu");
        },
        // 可选
        setUserInfo(state, userName) {
            state.userName = userName;
        }
    },

    actions: {
        // removeToken: (context) => {
        // context.commit('setToken')
        // }
    },

})

export default store